import React, { Fragment, useEffect } from "react";

import AOS from "aos";

import { Navbar } from "components/common";
import { LetsTalk } from "components/ui";

import "aos/dist/aos.css";

function HeroHeader() {
  return (
    <section
      className="-mt-20 h-screen"
      style={{
        backgroundImage: "url(/img/hero.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="mx-auto flex h-full max-w-7xl items-center py-20 px-4 sm:px-6 lg:px-8">
        <div
          data-aos="fade-up"
          className="w-full bg-[#d9d9d9]/10 px-10 py-12 text-white backdrop-blur-3xl"
        >
          <div
            data-aos="fade-up"
            className="mb-6 font-fjalla-one text-3xl uppercase tracking-widest lg:text-5xl lg:tracking-[0.2em] xl:tracking-[0.4em]"
          >
            Work flow Optimization
          </div>
          <div
            data-aos="fade-up"
            className="mb-6 max-w-2xl font-syne text-2xl font-bold lg:text-3xl"
          >
            Business Intelligence suite Customized to your work Environment
          </div>
          <div data-aos="fade-up">
            <a
              href="#about"
              className="flex select-none items-center font-syne font-bold"
            >
              <span>Learn more</span>{" "}
              <span className="material-icons-outlined ml-2">
                chevron_right
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

function AboutUs() {
  return (
    <section id="about" className="bg-rhino">
      <div className="mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <div className="text-white">
          <h1
            data-aos="fade-up"
            className="mb-10 font-fjalla-one text-3xl uppercase tracking-wider lg:text-5xl lg:tracking-widest"
          >
            about us
          </h1>
          <div
            data-aos="fade-up"
            className="font-syne text-xl lg:text-2xl lg:tracking-[0.02em]"
          >
            NewLogix, Inc is a leading provider of web and mobile applications
            customized to your work environment. Based in Chicago, Illinois and
            founded by professionals with over 30 years of web, mobile, and
            desktop application development experience, our products address the
            everyday challenges organizations face and enables our clients to
            manage their business instead of managing their data.
          </div>
        </div>
      </div>
    </section>
  );
}

function WhatWeDo() {
  return (
    <section>
      <div className="mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <div className="text-rhino lg:text-right">
          <h1
            data-aos="fade-up"
            className="mb-10 font-fjalla-one text-3xl uppercase tracking-wider lg:text-5xl lg:tracking-widest"
          >
            what we do
          </h1>
          <div
            data-aos="fade-up"
            className="mb-6 font-syne text-xl lg:text-2xl lg:tracking-[0.02em]"
          >
            We believe that success is based on consistent performance of
            defined processes and procedures. Workflow isn’t simply about
            getting the job done; it’s about bringing a sense of ease to
            complex, multi-party projects. It’s about creating effective
            partnership with the people you serve through efficient and modern
            technology.
          </div>
          <div
            data-aos="fade-up"
            className="font-syne text-xl lg:text-2xl lg:tracking-[0.02em]"
          >
            We integrate our products to the backbone of your workflow, which
            allows for centralized information access, instant online
            integration, improved communication features, and an easy-to-use
            application tailored for your operations.
          </div>
        </div>
      </div>
    </section>
  );
}

function Cards() {
  return (
    <section className="bg-rhino">
      <div className="mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-7">
          <div data-aos="fade-up" className="bg-white px-8 py-8 xl:col-span-3">
            <h3 className="mb-6 font-fjalla-one text-2xl uppercase leading-snug tracking-widest text-rhino lg:text-3xl">
              we <span className="text-azure-radiance">ask</span> about you
            </h3>
            <div className="font-syne text-lg text-rhino lg:text-xl lg:tracking-[0.02em]">
              We believe every organization is unique. We ask questions to gain
              knowledge and understanding of your needs to provide customized
              products and solutions. Our goal is to help you maximize
              productivity while increasing the benefits your team experience.
            </div>
          </div>
          <div data-aos="fade-up" className="bg-white px-8 py-8 xl:col-span-2">
            <h3 className="mb-6 font-fjalla-one text-2xl uppercase leading-snug tracking-widest text-rhino lg:text-3xl">
              we <span className="text-azure-radiance">listen</span> to you
            </h3>
            <div className="font-syne text-lg text-rhino lg:text-xl lg:tracking-[0.02em]">
              We believe in actively listening to your success stories, your
              challenges, your journey, and most importantly, your vision for
              the future.
            </div>
          </div>
          <div data-aos="fade-up" className="bg-white px-8 py-8 xl:col-span-2">
            <h3 className="mb-6 font-fjalla-one text-2xl uppercase leading-snug tracking-widest text-rhino lg:text-3xl">
              we <span className="text-azure-radiance">learn</span> from you
            </h3>
            <div className="font-syne text-lg text-rhino lg:text-xl lg:tracking-[0.02em]">
              We believe that in order for organizations to improve and grow, we
              must embrace a culture of learning. When we deploy our solutions
              to your organization, we will constantly learning to improve our
              customized solutions for you.
            </div>
          </div>
          <div data-aos="fade-up" className="bg-white px-8 py-8 xl:col-span-7">
            <h3 className="mb-6 font-fjalla-one text-2xl uppercase leading-snug tracking-widest text-rhino lg:text-3xl">
              we <span className="text-azure-radiance">repeat</span>
            </h3>
            <div className="font-syne text-lg text-rhino lg:text-xl lg:tracking-[0.02em]">
              We believe that excellence is driven by consistency. We will
              continue to ASK, LISTEN, LEARN and REPEAT again and again, so we
              can help you turn challenges into opportunities.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <Fragment>
      <Navbar isWhite />
      <HeroHeader />
      <AboutUs />
      <WhatWeDo />
      <Cards />
      <LetsTalk />
    </Fragment>
  );
}

export default Home;
