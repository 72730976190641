import { Fragment } from "react";

import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Layout } from "components/common";

import Clients from "pages/Clients";
import Home from "pages/Home";
import Service from "pages/Service";

import ScrollToTop from "utils/ScrollToTop";

function App() {
  return (
    <Fragment>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="service" element={<Service />} />
          <Route path="clients" element={<Clients />} />
        </Route>
      </Routes>
      <Toaster position="top-center" reverseOrder={false} />
    </Fragment>
  );
}

export default App;
