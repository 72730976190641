import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import AOS from "aos";
import { toast } from "react-hot-toast";
import clsx from "clsx";

import "aos/dist/aos.css";

const LetsTalk = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    fetch("https://api.civixapp.com/api/v1/contact-us", {
      method: "POST",
      body: JSON.stringify({
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        subject: data?.subject,
        message: data?.message,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(() => {
        setValue("name", "");
        setValue("email", "");
        setValue("phone", "");
        setValue("subject", "");
        setValue("message", "");

        toast.success(
          "Thanks for your message! A member of our team will be contacting you within 24-48 hours."
        );
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <section>
      <form
        className="mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1
          data-aos="fade-up"
          className="mb-16 font-fjalla-one text-3xl uppercase tracking-widest text-rhino lg:text-5xl"
        >
          let’s talk
        </h1>
        <div
          data-aos="fade-up"
          className="mb-6 grid grid-cols-1 gap-4 font-syne lg:grid-cols-2"
        >
          <div>
            <label>
              <div className="mb-2 text-lg text-rhino lg:text-xl">Name*</div>
              <input
                type={"text"}
                className={clsx(
                  errors?.name?.type === "required"
                    ? "border-[#ff0000] focus:border-[#ff0000]"
                    : "border-bombay focus:border-bombay",
                  "w-full rounded border focus:ring-0"
                )}
                {...register("name", { required: true })}
              />
            </label>
          </div>
          <div>
            <label>
              <div className="mb-2 text-lg text-rhino lg:text-xl">Phone</div>
              <input
                type={"tel"}
                className={clsx(
                  errors?.phone?.type === "required"
                    ? "border-[#ff0000] focus:border-[#ff0000]"
                    : "border-bombay focus:border-bombay",
                  "w-full rounded border focus:ring-0"
                )}
                {...register("phone", { required: false })}
              />
            </label>
          </div>
          <div>
            <label>
              <div className="mb-2 text-lg text-rhino lg:text-xl">Email*</div>
              <input
                type={"email"}
                className={clsx(
                  errors?.email?.type === "required"
                    ? "border-[#ff0000] focus:border-[#ff0000]"
                    : "border-bombay focus:border-bombay",
                  "w-full rounded border focus:ring-0"
                )}
                {...register("email", { required: true })}
              />
            </label>
          </div>
          <div>
            <label>
              <div className="mb-2 text-lg text-rhino lg:text-xl">Subject*</div>
              <input
                type={"text"}
                className={clsx(
                  errors?.subject?.type === "required"
                    ? "border-[#ff0000] focus:border-[#ff0000]"
                    : "border-bombay focus:border-bombay",
                  "w-full rounded border focus:ring-0"
                )}
                {...register("subject", { required: true })}
              />
            </label>
          </div>
          <div className="lg:col-span-2">
            <label>
              <div className="mb-2 text-lg text-rhino lg:text-xl">Message*</div>
              <textarea
                rows={3}
                maxLength={255}
                className={clsx(
                  errors?.message?.type === "required"
                    ? "border-[#ff0000] focus:border-[#ff0000]"
                    : "border-bombay focus:border-bombay",
                  "w-full resize-none rounded border focus:ring-0"
                )}
                {...register("message", {
                  required: true,
                  maxLength: 255,
                })}
              />
            </label>
          </div>
        </div>
        <div data-aos="fade-up" className="flex justify-center">
          <button
            type="submit"
            className="rounded-full bg-azure-radiance px-6 py-2 font-syne text-white"
          >
            Send message
          </button>
        </div>
      </form>
    </section>
  );
};

export default LetsTalk;
