import React from "react";

import { Link } from "react-router-dom";

const navigation = [
  { title: "Home", href: "/" },
  { title: "Service", href: "/service" },
  { title: "Clients", href: "/clients" },
];

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl border-t border-bombay py-20 px-4 font-dm-sans sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-between sm:flex-row">
          <div className="mb-10 sm:mb-0">
            <Link to={"/"} className="mb-10 block sm:mb-8">
              <img
                src="/img/brand-logo.png"
                alt="Brand Logo"
                className="h-12 object-contain"
              />
            </Link>
            <div className="flex flex-col items-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-14">
              {navigation.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={item.href}
                    className="text-base text-rhino sm:text-lg"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          </div>
          <div>
            <div className="mb-4 text-center text-base text-rhino sm:text-right sm:text-lg">
              444 N. Michigan Ave., <br /> Suite 1200 <br /> Chicago, IL 60611
            </div>
            <div className="text-center text-azure-radiance sm:text-right">
              (888) 540-3775
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
