import React, { Fragment, useEffect } from "react";

import AOS from "aos";

import { Navbar } from "components/common";
import { LetsTalk } from "components/ui";

import "aos/dist/aos.css";

function HeroHeader() {
  return (
    <section
      className="-mb-20 h-screen"
      style={{
        backgroundImage: "url(/img/services-hero.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="mx-auto flex h-full max-w-7xl items-center justify-center py-20 px-4 sm:px-6 lg:px-8">
        <div className="-mt-20 text-center font-fjalla-one text-[80px] uppercase tracking-[0.2em] text-rhino lg:text-[100px] lg:tracking-[0.4em]">
          <div data-aos="fade-up">civix</div>
        </div>
      </div>
    </section>
  );
}

function ApplicationProcess() {
  return (
    <section className="bg-rhino">
      <div className="mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <div className="text-white">
          <h1
            data-aos="fade-up"
            className="mb-10 max-w-4xl font-fjalla-one text-3xl uppercase leading-tight tracking-wider lg:text-5xl lg:tracking-widest"
          >
            simplify the application process with{" "}
            <span className="text-azure-radiance">civix</span>
          </h1>
          <div
            data-aos="fade-up"
            className="mb-12 font-syne text-xl lg:text-2xl lg:tracking-[0.02em]"
          >
            Applying for permits, licenses and registration by using traditional
            counter services can be a manual and time consuming process. Each
            city has their own unique set of rules and regulations and this
            variability in requirements makes it difficult to comply-leading to
            delays and frustration. CIVIX simplifies the application process by
            providing users with an information hub that facilitates the process
            online, from start to finish.
          </div>
          <div data-aos="fade-up" className="flex items-center space-x-4">
            <a
              href="https://civix.vercel.app/"
              target="_blank"
              rel="noreferrer"
              className="bg-white px-6 py-4 text-center font-fjalla-one text-lg uppercase text-rhino lg:px-12 lg:text-xl"
            >
              visit website
            </a>
            <a
              href="https://civix-email-template.s3.us-east-2.amazonaws.com/Image+from+iOS.mp4"
              target="_blank"
              rel="noreferrer"
              className="bg-white px-6 py-4 text-center font-fjalla-one text-lg uppercase text-rhino lg:px-12 lg:text-xl"
            >
              watch video presentation
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

function Benefits() {
  return (
    <section>
      <div className="mx-auto max-w-7xl border-b border-rhino py-20 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 divide-y divide-rhino">
          <div
            data-aos="fade-up"
            className="grid grid-cols-1 divide-y divide-rhino lg:grid-cols-2 lg:divide-y-0 lg:divide-x"
          >
            <div className="flex flex-col py-16 text-rhino sm:flex-row sm:space-x-8 sm:px-10">
              <div className="flex-none">
                <img
                  src="/img/services/1.png"
                  alt="alt-img"
                  className="mb-6 h-14 w-14 sm:mb-0 sm:h-16 sm:w-16"
                />
              </div>
              <div>
                <div className="mb-4 font-fjalla-one text-2xl uppercase lg:text-3xl">
                  easy to use
                </div>
                <div className="font-syne text-lg lg:text-xl">
                  Civix is web-based, you can apply for a permit, license, or
                  registration from your home or work computer, whenever it’s
                  convenient for you. Anywhere, anytime, 24/7
                </div>
              </div>
            </div>
            <div className="flex flex-col py-16 text-rhino sm:flex-row sm:space-x-8 sm:px-10">
              <div className="flex-none">
                <img
                  src="/img/services/2.png"
                  alt="alt-img"
                  className="mb-6 h-14 w-14 sm:mb-0 sm:h-16 sm:w-16"
                />
              </div>
              <div>
                <div className="mb-4 font-fjalla-one text-2xl uppercase lg:text-3xl">
                  green
                </div>
                <div className="font-syne text-lg lg:text-xl">
                  CIVIX helps citizens and government agencies go green! by
                  eliminating the use of paper for the application process,
                  CIVIX is eco-friendly and environmentally responsible
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="grid grid-cols-1 divide-y divide-rhino lg:grid-cols-2 lg:divide-y-0 lg:divide-x"
          >
            <div className="flex flex-col py-16 text-rhino sm:flex-row sm:space-x-8 sm:px-10">
              <div className="flex-none">
                <img
                  src="/img/services/3.png"
                  alt="alt-img"
                  className="mb-6 h-14 w-14 sm:mb-0 sm:h-16 sm:w-16"
                />
              </div>
              <div>
                <div className="mb-4 font-fjalla-one text-2xl uppercase lg:text-3xl">
                  efficient
                </div>
                <div className="font-syne text-lg lg:text-xl">
                  CIVIX makes the application process-from submission to
                  approval- a snap! No more filling out paper forms or standing
                  in lines to apply. Working with your city to get what you need
                  is quick and easy
                </div>
              </div>
            </div>
            <div className="flex flex-col py-16 text-rhino sm:flex-row sm:space-x-8 sm:px-10">
              <div className="flex-none">
                <img
                  src="/img/services/4.png"
                  alt="alt-img"
                  className="mb-6 h-14 w-14 sm:mb-0 sm:h-16 sm:w-16"
                />
              </div>
              <div>
                <div className="mb-4 font-fjalla-one text-2xl uppercase lg:text-3xl">
                  secure
                </div>
                <div className="font-syne text-lg lg:text-xl">
                  CIVIX goes above and beyond industry standards for securing
                  data and sensitive information. You can have confidence
                  knowing that your personal information is kept safe by
                  adhering with advanced levels of protection
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Service() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <Fragment>
      <Navbar />
      <HeroHeader />
      <ApplicationProcess />
      <Benefits />
      <LetsTalk />
    </Fragment>
  );
}

export default Service;
