import React, { Fragment, useEffect } from "react";

import AOS from "aos";

import { Navbar } from "components/common";
import { LetsTalk } from "components/ui";

import "aos/dist/aos.css";

const clients = [
  { imageSrc: "/img/clients/intern.png" },
  { imageSrc: "/img/clients/jmf.png" },
  { imageSrc: "/img/clients/kdm-engineering.png" },
  { imageSrc: "/img/clients/l2cms.png" },
  { imageSrc: "/img/clients/pg-e.png" },
];

function HeroHeader() {
  return (
    <section
      className="-mb-20 h-screen"
      style={{
        backgroundImage: "url(/img/clients-hero.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="mx-auto flex h-full max-w-7xl items-center py-20 px-4 sm:px-6 lg:px-8">
        <div
          data-aos="fade-up"
          className="-mt-20 w-full bg-[#d9d9d9]/10 px-12 py-20 text-center font-fjalla-one text-2xl uppercase leading-9 tracking-wide text-rhino backdrop-blur-[70px] lg:text-4xl lg:leading-[50px]"
        >
          <div data-aos="fade-up">
            NewLogix works with a wide array of industries, both{" "}
            <span className="text-azure-radiance">small</span> and{" "}
            <span className="text-azure-radiance">large</span>. A sampling of
            our clients includes
          </div>
        </div>
      </div>
    </section>
  );
}

function Partners() {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-7xl border-b border-rhino py-20 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-center gap-20">
          {clients.map((client, i) => {
            return (
              <div key={i} data-aos="fade-up">
                <img
                  src={client.imageSrc}
                  alt="alt-img"
                  className="h-16 w-auto sm:h-20 lg:h-24 xl:h-28"
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Clients() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <Fragment>
      <Navbar />
      <HeroHeader />
      <Partners />
      <LetsTalk />
    </Fragment>
  );
}

export default Clients;
