import React from "react";

import { Link, NavLink, useMatch, useResolvedPath } from "react-router-dom";

import clsx from "clsx";

const navigation = [
  { title: "Home", href: "/" },
  { title: "Service", href: "/service" },
  { title: "Clients", href: "/clients" },
];

const CustomNavLink = ({ to, title, isWhite }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink
      to={to}
      className={clsx(
        match ? "border-b-4 pb-1.5" : "",
        isWhite ? "border-white" : "border-rhino"
      )}
    >
      {title}
    </NavLink>
  );
};

const Navbar = ({ isWhite = false }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <nav>
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between font-dm-sans">
            <Link to={"/"}>
              <img
                src="/img/brand-logo.png"
                alt="Brand Logo"
                className="h-12 object-contain"
              />
            </Link>
            <div
              className={clsx(
                isWhite ? "text-white" : "text-rhino",
                "hidden space-x-28 text-lg font-bold lg:block"
              )}
            >
              {navigation.map((item, i) => {
                const { title, href } = item;
                return (
                  <CustomNavLink
                    key={i}
                    to={href}
                    title={title}
                    isWhite={isWhite}
                  />
                );
              })}
            </div>
            <span
              className={clsx(
                isWhite ? "text-white" : "text-rhino",
                "material-icons block lg:hidden"
              )}
              onClick={() => setIsOpen(true)}
            >
              menu
            </span>
          </div>
        </div>
      </nav>

      {isOpen && (
        <div
          className="fixed inset-0 z-10 block bg-white/30 p-2.5 backdrop-blur-lg lg:hidden"
          onClick={() => setIsOpen(false)}
        >
          <div className="relative min-h-[182px] w-full rounded-xl border bg-white shadow">
            <span
              className="material-icons absolute right-1.5 top-2.5 block text-rhino lg:hidden"
              onClick={() => setIsOpen(false)}
            >
              close
            </span>
            <div className="flex flex-col space-y-4 px-6 py-8 font-dm-sans text-xl font-medium">
              {navigation.map((nav, i) => {
                return (
                  <Link
                    key={i}
                    to={`${nav.href}`}
                    onClick={() => setIsOpen(false)}
                    className="w-fit text-rhino"
                  >
                    {nav.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
